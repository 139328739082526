import Api from '@/services/Api';
import axios from 'axios'

export default {
    search(search) {
        return axios.get('http://www.omdbapi.com/?s=' + search + '&apikey=46375f6b')
        // http://www.omdbapi.com/?s=GOD&apikey=46375f6b
        // return {}
    },

    searchSingleMovie(title) {
        return axios.get('http://www.omdbapi.com/?t=' + title + '&apikey=46375f6b')
    },










    ////

    addThirdPartyMovies(data) {
        return Api().post('api/dashuser/MapMovies/', data)

    },
    getMoviesAccordingCinema(payload) {
        return Api().post('api/dashuser/ShowDateFilter', payload)
    },


    getMoviesAccordingCinemaForTran(payload) {
        return Api().post('api/dashuser/TransDateFilter/', payload)
    },


    getMoviesListData() {
        return Api().get('api/dashuser/GetddlMoviesList')
    },





    // New Saif

    getMovieByID(payload) {
        return Api().post('/admin/getmoviedetails', payload)
    },
    getMovieByThirdpartyID(payload) {
        return Api().post('api/thirdparty/MapMovieDetails/', payload)
    },
    getMovies() {
        return Api().post('/api/dashuser/GetMoviesList')
    },

    getFormatData(payload) {
        return Api().post('api/dashuser/GetMovieFormatLanguage/', payload)
    },
    getMasterMovies() {
        return Api().post('api/dashuser/GetMasterMoviesList')
    },
    getGenreList() {
        return Api().post('api/dashuser/GetGenreList')
    },

    // NEW API FOR NEW MOVIE PAGE


    // getMoviesByCountry(payload) {
    //     return Api().post('api/dashuser/LinkMovie', payload)
    // },

    // getMoviesDDL() {
    //     return Api().post('api/dashuser/GetddlMoviesList')
    // },

    /**
     * BACKUP FOR OLD
     */
    // getContentLanguageList() {
    //     return Api().post('api/dashuser/GetContentLang')
    // },
    // getProviderMovies() {
    //     return Api().post('api/thirdparty/MapMovieList/')
    // },

    // addMovie(body) {
    //     if (body.MovieID != null) {
    //         body.Flag = 2;
    //     } else {
    //         body.MovieID = undefined;
    //         body.Flag = 1;
    //     }
    //     return Api().post('/api/dashuser/AddMovies', body)
    // },
    // getLinkMoviesList() {
    //     return Api().post('api/dashuser/MovieFormatLanguageList')
    // },
    // getProviderMoviesList() {
    //     return Api().post('api/dashuser/LinkMovieList')
    // },
    // onLinkMovie(payload) {
    //     return Api().post('api/dashuser/LinkMovie', payload)
    // },

    // getProviderMovies() {
    //     return Api().post('admin/thirdparty/mapmovielist')
    // },

    /**
     * NEW API SAIF
     */

    // getActiveMovies() {
    //     return Api().post('admin/getactivemovies')
    // },
    getCriticsRatings(payload) {
        return Api().post('api/dashuser/GetCriticReviewList', payload)
    },
    getActiveShows(payload) {
        return Api().post('admin/getactiveshows', payload)
    },
    getActiveMovies(payload) {
        payload.Source = "cineco",
    payload.organization_id= "3"
        return Api().post('admin/getactivemovies', payload)
    },
    getActiveComingSoonMovies(payload) {
        return Api().post('admin/getactivecomingsoonmovies', payload)
    },
    getUpcomingMovies(payload) {
        return Api().post('admin/getupcomingmovies', payload)
    },
    getContentLanguageList() {
        return Api().post('admin/getactivecontentlanguages')
    },
    addCriticRating(payload) {
        return Api().post('api/dashuser/AddCriticReview', payload)
    },
    addComingSoon(payload) {
        return Api().post('api/dashuser/AddComingSoon', payload)
    },
    activateOrDeactivateComingSoonMovie(payload) {
        return Api().post('admin/activateOrDeactivateComingSoonMovie', payload)
    },
    addMovie(body) {
        // if (body.MovieID != null) {
        //     body.Flag = 2;
        // } else {
        //     body.MovieID = undefined;
        //     body.Flag = 1;
        // }
        return Api().post('admin/addmovie', body)
    },
    getLinkMoviesList() {
        let payload = {
            "Source": "cineco",
            "organization_id": "3"
        }

        return Api().post('admin/getlinkmovies', payload)
    },
    getProviderMoviesList() {
        let payload = {
            "Source": "cineco",
            "organization_id": "3"
        }
        return Api().post('admin/getprovidermovies', payload)
    },
    onLinkMovie(payload) {
        return Api().post('admin/linkmovie', payload)
    },

    // New
    getBannerByID(payload) {
        return Api().post('api/Banner/GetBannerByID', payload)
    },


}